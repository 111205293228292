import * as Routes from '../js/routes.js.erb';
window.Routes = Routes;
/**
  * These are simple redirect-routes, whereas no ?ort=../?ort_id=.. is needed
  * after setting the meinOrt, ie. requesting https://meinort.app/17-Brey/events
  * will set the meinOrt and then js-redirect to https://meinort.app/events
  * from app/views/layouts/mein_ort_welcome.html.haml w/o ?ort=17.
  */
const SIMPLE_REDIRECT_ROUTES = [
  '/events',
  '/entdecken',
  '/jobs',
  '/neues',
  '/stellenangebote',
  '/verwaltung'
];

$(document).on('turbo:load', async function() {
    let timeout = null;
    $('body').on("keyup", '#ort-search', async function() {
        $('#ort-search-id').val("");
        let element_ = $("#root-suggestions");
        element_.empty();
        let search_text = document.getElementById("ort-search").value;
        // just return in case of nothing has been entered
        if (search_text == undefined || search_text.length == 0) {
            element_.css({ "padding": "0px" })
            return;
        }
        element_.append(`<div id="list_loader" class="row mt-3"><div class="col-12 justify-content-center text-center"><div class="spinner-border text-primary text-center" role="status"></div></div></div>`);
        clearTimeout(timeout);
        timeout = setTimeout(async function () {
          let suggestions = await getSuggestions(search_text);
          if (suggestions.data.length > 0) {
            $("#list_loader").remove();
              element_.css({ "padding": "15px", "width": "350px" });
              $.each(suggestions.data, function(key, value) {
                  element_.append(`<li class="row mt-1 c-pointer" data-id="${value["id"]}" data-name="${value["name"]}" data-logo="${value["logo"]}">
                  <div class="col-11 align-items-center d-flex link-dark text-decoration-none">
                      <img style="height: 25px" class="img-fluid" src="${value["logo"] ? value["logo"] : '/icons/app_logo.svg'}">
                      <span class="mb-0 ps-2">${value["name"]}</span>
                  </div>
                  <div class="col-1 d-flex justify-content-end pe-0 align-items-center">
                    <div class="icon-arrow-right-1 text-default-gray pe-1"></div>
                  </div>` + `${ (suggestions.data.length > 1) ? `<hr class="mt-3 border">`: '' }` + `
                  </li>`);
              });
          } else {
            $("#list_loader").remove();
              element_.css({ "padding": "10px" });
              element_.empty();
              element_.append(`<div style="display: flex; flex-direction: column; align-items: center;">
                                <h3 style="font-size: 1.25rem;" class="fw-bold text-center">Dein Wunschort ist nicht verfügbar?</h3>
                                <p style="margin-top: 10px;" class="fs-small m-0 text-center">
                                  Schreibe uns eine Nachricht mit unserem Kontaktformular - wir nehmen
                                  Kontakt mit Deiner Verwaltung auf.
                                </p>
                              <a href="/kontakt" style="margin-top: 5px;" class="fs-smaller">Zum Kontaktformular</a>
                            </div>`);
          }
        }, 500);
    });

    $('body').on("keyup", '#ort-search-back', async function() {
        $('#ort-search-id-back').val("");
        let element_ = $("#root-suggestions-back");
        element_.empty();
        let search_text = document.getElementById("ort-search-back").value;
        // just return in case of nothing has been entered
        if (search_text == undefined || search_text.length == 0) {
            element_.css({ "padding": "0px" })
            return;
        }
        element_.append(`<div id="list_loader_back" class="row mt-3"><div class="col-12 justify-content-center text-center"><div class="spinner-border text-primary text-center" role="status"></div></div></div>`);
        clearTimeout(timeout);
        timeout = setTimeout(async function () {
          let suggestions = await getSuggestions(search_text);
          if (suggestions.data.length > 0) {
            $("#list_loader_back").remove();
              element_.css({ "padding": "15px" });
              $.each(suggestions.data, function(key, value) {
                  element_.append(`<li class="row mt-1 c-pointer" data-id="${value["id"]}" data-name="${value["name"]}" data-logo="${value["logo"]}">
                  <div class="col-11 align-items-center d-flex link-dark text-decoration-none">
                      <img style="height: 25px" class="img-fluid" src="${value["logo"] ? value["logo"] : '/icons/app_logo.svg'}">
                      <span class="mb-0 ps-2">${value["name"]}</span>
                  </div>
                  <div class="col-1 d-flex justify-content-end pe-0 align-items-center">
                    <div class="icon-arrow-right-1 text-default-gray pe-1"></div>
                  </div>` + `${ (suggestions.data.length > 1) ? `<hr class="mt-3 border">`: '' }` + `
                  </li>`);
              });
          } else {
            $("#list_loader_back").remove();
              element_.css({ "padding": "10px" });
              element_.empty();
              element_.append(`<div style="display: flex; flex-direction: column; align-items: center;">
                                <h3 style="font-size: 1.5em;" class="fw-bold">Dein Wunschort ist nicht verfügbar?</h3>
                                <p style="margin-top: 10px;" class="text-center m-0">
                                  Schreibe uns eine Nachricht mit unserem Kontaktformular - wir nehmen
                                  Kontakt mit Deiner Verwaltung auf.
                                </p>
                              <a href="/kontakt" style="margin-top: 10px;">Zum Kontaktformular</a>
                            </div>
                          `);
          }
        }, 500);
    });
    $(document).on('click', 'ul#root-suggestions-back li', function() {
        let element_ = $("#root-suggestions-back");
        let new_fav = { id: $(this).data("id"), name: $(this).data("name"), logo: $(this).data("logo") };
        window.localStorage.setItem('main_city', JSON.stringify(new_fav));
        $("#ort-search").val($(this).data("name"));
        $("#ort-search-id").val($(this).data("id"));
        element_.css({ "width": "0%", "padding": "0px" });
        element_.empty();
        Turbo.visit('//welcome?ort=' + $(this).data('id'), { action: 'replace' })
    });
    $(document).on('click', 'ul#root-suggestions li', function() {
        let element_ = $("#root-suggestions");
        let new_fav = { id: $(this).data("id"), name: $(this).data("name"), logo: $(this).data("logo") };
        window.localStorage.setItem('main_city', JSON.stringify(new_fav));
        $("#ort-search").val($(this).data("name"));
        $("#ort-search-id").val($(this).data("id"));
        element_.css({ "width": "0%", "padding": "0px" });
        element_.empty();
    });

    $("#root-form").submit(function(e) {
        e.preventDefault();
        if ($('#root-suggestions').children().length == 1) {
            let elem = $('#root-suggestions').children()[0];
            let new_fav = { id: $(elem).data("id"), name: $(elem).data("name"), logo: $(elem).data("logo") };
            window.localStorage.setItem('main_city', JSON.stringify(new_fav));
            $("#ort-search").val($(elem).data("name"));
            $("#ort-search-id").val($(elem).data("id"));
            subscribe_ort_push()
                .then(() => this.submit());
        } else if ($('#ort-search-id').val() == "") {
            $('#ort-not-found-modal').modal('show');
        } else {
            subscribe_ort_push()
                .then(() => this.submit());
        }
    });
    //scroll to appropriate anchor position
    $(".scroll").click(function(event) {
        event.preventDefault();
        var offset = $($(this).attr("href")).offset().top;
        $('html,body').animate({
            scrollTop: offset
        });
    });
});

async function subscribe_ort_push() {
    navigator.serviceWorker && navigator.serviceWorker.ready.then(registration => {
        registration.pushManager.getSubscription()
            .then(subscription => {
                if (subscription) {
                    window.Notification.requestPermission().then(permission => {
                        let main_city_ = JSON.parse(localStorage.getItem('main_city'));
                        if (permission === 'granted') {
                            localStorage.setItem('notification_city', JSON.stringify(main_city_));
                            window.localStorage.setItem('notification_types', JSON.stringify({
                                'push-kind-1': true,
                                'push-kind-2': true,
                                'push-kind-3': true,
                                'push-kind-4': true,
                            }));
                            let formData = new FormData();
                            formData.append('ort_id', $("#ort-search-id").val());
                            formData.append('subscription', JSON.stringify(subscription));
                            return fetch(Routes.subscribe_path(), {
                                method: 'post',
                                headers: {
                                    "X-CSRF-Token": document.querySelectorAll('meta[name="csrf-token"]')[0]["content"],
                                    'X-Requested-With': 'XMLHttpRequest',
                                },
                                body: formData
                            });
                        }
                    });
                }
            })
            .catch(function(err) {
                console.log('Error during getSubscription()', err);
            });
    });
}

async function getSuggestions(search) {
    const response = await fetch('/ort-search?search=' + search, {
        method: 'get',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
    }).then(res => res.clone().json())
    return response;
}
$(document).on('turbo:load', async function() {
  // if accessing /welcome continue, otherwise return
  if (document.location.href.includes('welcome')) { }
  else { return }

  //$('.progress-bar').css('width', 100 + '%');
  // if window.localStorage.getItem("main_city") is null, we assume the
  // currently given main_city in the request, eg: "/welcome?ort=17" is
  // the desired main_city

  let main_city = window.localStorage.getItem('main_city');
  let s_ort_id  = window.location.href.split('?ort=')[1];
  let n_ort_id  = parseInt(s_ort_id);
  let isNumber  = !isNaN(n_ort_id);
  isNumber && window.localStorage.setItem('current_city_id', JSON.stringify(n_ort_id));
  if (!main_city && isNumber) {
    let new_fav = {
      id:   n_ort_id,
      name: $("#main_city_logo").data("ortname"),
      logo: $("#main_city_logo").attr("src")
    };

    window.localStorage.setItem('main_city', JSON.stringify(new_fav));
  }

  setTimeout(function() {
    // window.location.href = Routes.neues_path();
    Turbo.setProgressBarDelay(10);
    // check if we want to perform a redirect
    if (window.location.search.substr(1).indexOf('redirect=') != -1) {
      // collect all url-arguments
      let args = {}; // will include all key-value-pairs given within the url
      let ar = window.location.search.substr(1).split('&');
      for (let keyval_pair of ar) { // eg: 'token=337b0ea7e96a0e'
        let [key, val] = keyval_pair.split('='); // eg: key='push-kind-1'
        args[key] = val;
      }

      // transform '%2Feinstellungen' into '/einstellungen'
      args.redirect = decodeURIComponent(args.redirect);
      let redirect = args.redirect;
      delete args.redirect;

      if (redirect == '/einstellungen') {
        Turbo.visit(Routes.einstellungen_path(args));
      } else {
        let path = redirect;
        Object.keys(args).forEach(function (key, idx) {
          path += `${idx==0 ? '?' : '&'}${key}=${args[key]}`
        });
        // if simple redirect is requested - eg goto /events after setting the
        // meinOrt - we remove additional req-params (such as ort=9 for example)
        if (SIMPLE_REDIRECT_ROUTES.includes(redirect) &&
            Object.keys(args).length &&
            Object.keys(args)[0] == 'ort') {
          Turbo.visit(redirect); // only visit /events for example
        } else {
          Turbo.visit(path); // visit /events?ort=17&xxx=yyy with all args
        }
      }
    } else {
      // no redirection specified, perform the default: go to /neues
      Turbo.visit(Routes.neues_path());
    }

    let old_progress = 0;
    setTimeout(function() {
      const turboObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          let progress = Math.round((entry.contentRect.width / window.innerWidth) * 100);
          if (progress > old_progress) {
              $('.progress-bar').css('width', progress + '%');
              $('.progress-bar').attr('aria-valuenow', progress);
          }
          old_progress = progress;
        });
      });
      turboObserver.observe($('div.turbo-progress-bar')[0]);
    }, 25);
  }, 500);

  let weather_info_ = await getWeatherInfo();
  if(weather_info_.data.error) {
    window.localStorage.removeItem('weather_info');
    console.warn(weather_info_.data.error);
  } else {
    window.localStorage.setItem('weather_info', JSON.stringify(weather_info_));
  }
  let next_events_ = await getNextEvents();
  next_events_.data.data.length > 0 ? window.localStorage.setItem('next_events', JSON.stringify(next_events_)) : window.localStorage.removeItem('next_events');

  let g_district = JSON.parse(window.localStorage.getItem('garbage_district'));
  if (g_district != undefined) {
    let g_fetched = JSON.parse(window.localStorage.getItem('garbage_fetched')); // eg: '2021-12-01T10:15:26.383Z'
        g_fetched = (g_fetched == undefined)
                     ? null
                     : new Date(g_fetched);
    let is_old = g_fetched == undefined ||
                 g_fetched.getDay()   != new Date().getDay()   ||
                 g_fetched.getMonth() != new Date().getMonth() ||
                 g_fetched.getYear()  != new Date().getYear();

    if (is_old ||
        window.localStorage.getItem('garbage_types') == undefined ||
        window.localStorage.getItem('garbage_dates') == undefined) {
      let garbage_dates_ = await setGarbageDistrict(g_district.id);
      let garbage_types_ = await getGarbageTypes();
      window.localStorage.setItem('garbage_types',   JSON.stringify(garbage_types_));
      window.localStorage.setItem('garbage_dates',   JSON.stringify(garbage_dates_));
      window.localStorage.setItem('garbage_fetched', JSON.stringify(new Date().toISOString()));
    }
  }
});

async function getWeatherInfo() {
    const response = await fetch('/get_weather', {
        method: 'get',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
    }).then(res => res.clone().json())
    return response;
}

async function getNextEvents() {
    const response = await fetch('/get_next_events', {
        method: 'get',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
    }).then(res => res.clone().json())
    return response;
}

async function setGarbageDistrict(g_id) {
  const settings = {
    method: 'POST',
    headers: {
      Accept:         'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      gid:   g_id,
      token: window.localStorage.getItem('email-sub-token')
    })
  };
  // in order to save
  const resp = await fetch('/set_garbage_district_id.json', settings).
    then(res => res.clone().json());
  return resp;
}

async function getGarbageTypes() {
  const response = await fetch('/garbage-types', {
    method: 'get',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  }).then(res => res.clone().json())
  return response.data;
}
