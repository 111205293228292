// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

// Import js route helpers
import * as Routes from '../js/routes.js.erb';
window.Routes = Routes;

//Import js files
import '../js/application.js';
import '../js/bootstrap_js_files.js';
import '../js/home.js';
import 'leaflet/dist/leaflet';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import '../js/home_map.js';

// Import css files
import 'packs/application.scss';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';


// Import static files
const images = require.context("../images", true)
const imagePath = name => images(name, true)

Rails.start()
ActiveStorage.start()