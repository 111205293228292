var $ = require("jquery");
import 'slick-carousel';
import Swiper from 'swiper';
import lightbox from 'lightbox2/dist/js/lightbox';
import moment from "moment/moment.js";
// const NOTIFICATION_TYPES = {
//   push-kind-1: "News",
//   push-kind-2: "Veranstaltungen",
//   push-kind-3: "Müll",
//   push-kind-4: "Advertising",
// };

window.addEventListener('load', () => {
    main();
});

const main = async() => {
    check();
    const swRegistration = await registerServiceWorker();
    const permission = await requestNotificationPermission();
    /*Show local notification */
    // showLocalNotification('This is title', 'this is the message', swRegistration);
}

//PUSH TEST
const check = () => {
    if (!('serviceWorker' in navigator)) {
        throw new Error('No Service Worker support!')
    }
    if (!('PushManager' in window)) {
        throw new Error('No Push API Support!')
    }
}

// I added a function that can be used to register a service worker.
const registerServiceWorker = async() => {
    const swRegistration = await navigator.serviceWorker.register('/service-worker.js');
    return swRegistration;
}

const requestNotificationPermission = async () => {
  const permission = await window.Notification.requestPermission();
  if (permission === 'granted') {
    navigator.serviceWorker && navigator.serviceWorker.ready.then(async (registration) => {
      try {
        const applicationServerKey = urlB64ToUint8Array(process.env.SUBSCRIPTION_PUBLIC_KEY)
        const options = {
          applicationServerKey,
          userVisibleOnly: true
        }
        const subscription = await registration.pushManager.subscribe(options)
        // console.log(JSON.stringify(subscription))
      } catch (err) {
        console.log('Error', err)
      }
    });
  } else {
    console.log('Permission not granted for Notification');
  }
}

// urlB64ToUint8Array is a magic function that will encode the base64 public key
// to Array buffer which is needed by the subscription option
const urlB64ToUint8Array = base64String => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')
    const rawData = atob(base64)
    const outputArray = new Uint8Array(rawData.length)
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
}

const showLocalNotification = (title, body, swRegistration) => {
    var options = {
        body,
        tag: 'simple-push-demo-notification',
        icon: '/icons/app_logo_button_256.png',
        badge: '/icons/app_logo_button_256.png'
    };
    // const options = {
    //   "//": "Visual Options",
    //   "body": "<String>",
    //   "icon": "<URL String>",
    //   "image": "<URL String>",
    //   "badge": "<URL String>",
    //   "vibrate": "<Array of Integers>",
    //   "sound": "<URL String>",
    //   "dir": "<String of 'auto' | 'ltr' | 'rtl'>",
    //   "//": "Behavioural Options",
    //   "tag": "<String>",
    //   "data": "<Anything>",
    //   "requireInteraction": "<boolean>",
    //   "renotify": "<Boolean>",
    //   "silent": "<Boolean>",
    //   "//": "Both Visual & Behavioural Options",
    //   "actions": "<Array of Strings>",
    //   "//": "Information Option. No visual affect.",
    //   "timestamp": "<Long>"
    // }
    swRegistration.showNotification(title, options);
}

//Document Ready

function redirectToWelcome() {
  let main_city = JSON.parse(window.localStorage.getItem("main_city"));
  if (jQuery.isEmptyObject(main_city)) { return false; }

  let route = Routes.welcome_path({ ort: main_city.id });
  window.location.href = route;
  return true;
}

function addWeatherToView() {
    let val = JSON.parse(window.localStorage.getItem('weather_info'));
    let w_html_list = "";
    let requested_at = moment(val.data.weather.requested_at).lang("de");
    if (!val)
      return;

    val && val.data.weather.forecasts.forEach(weather => {
      w_html_list = w_html_list + `<div class="col-3 text-center">
        <div class="row">
        <div><img class="img-fluid" width="40px" src="/icons/weather/${weather.icon}.png"/></div>
        </div>
        <div class="row">
        <div class="col-12">
        <p class="fw-bold mb-0 temperature-degree fs-xs">${weather["temp_min"] == weather["temp_max"] ? weather["temp_max"] : weather["temp_min"] + " - " + weather["temp_max"]}</p>
        </div>
        </div>
        <div class="row">
        <div class="col-12">
        <p class="text-muted mb-0 fs-xxs">${new Date(weather["time"]).toLocaleTimeString([], { hourCycle: 'h23', hour: '2-digit', minute: '2-digit' })}</p>
        </div>
        </div>
        </div>`;
    });
    let w_html_ = `<div class="row">
      <div class="col-12">
      <div class="box">
      <div class="row">
      <div class="box-middle">
      <div class="row">
      <div class="col-3">
      <div><img class="img-fluid" src="/icons/weather/${val.data.weather.icon}.png"/></div>
      </div>
      <div class="col-5 ps-0">
      <div class="h5 mb-0">${$(".main_city_name").text()}
      </div>
      <p class="text-thin m-b-1 fs-xs">${val.data.weather.desc}</p>
      </div>
      <div class="col-3 d-flex align-items-top justify-content-end pe-0">
      <h3 class="fw-bold temperature-degree">${val.data.weather.temp}</h3>
      </div>
      </div>
      <div class="row">` + `${w_html_list}` + ` </div>
      <div class="row">
      <div class="d-flex justify-content-end">
      <p class="text-thin fs-xs mt-3 mb-0 me-2">Quelle: <a href="http://www.wetter.net" target="_blank">http://www.wetter.net</a> | ${requested_at.format('ddd, HH:mm')} Uhr</p>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>`;
    $('#weather-info-to-view-box').append(w_html_);
}

function addWeather_info_admin() {
    let val = JSON.parse(window.localStorage.getItem('weather_info'));
    let requested_at = moment(val.data.weather.requested_at).lang("de");
    if (!val)
      return;

    let w_html_list = "";
    val && val.data.weather.forecasts.forEach(weather => {
      w_html_list = w_html_list + `<div class="col-3 text-center text-white">
        <div class="row">
        <div><img class="img-fluid" width="40px" src="/icons/weather/${weather.icon}.png"/></div>
        </div>
        <div class="row">
        <div class="col-12">
        <p class="text-white fw-bold mb-0 temperature-degree fs-xs">${weather["temp_min"] == weather["temp_max"] ? weather["temp_max"] : weather["temp_min"] + " - " + weather["temp_max"]}</p>
        </div>
        </div>
        <div class="row">
        <div class="col-12">
        <p class="text-white mb-0 fs-xxs">${new Date(weather["time"]).toLocaleTimeString([], { hourCycle: 'h23', hour: '2-digit', minute: '2-digit' })}</p>
        </div>
        </div>
        </div>`;
    });
    let w_html_ = `<div class="row">
      <div class="col-3">
      <div class="d-flex justify-content-center">
      <img class="img-fluid" src="/icons/weather/${val.data.weather.icon}.png"/>
      </div>
      </div>
      <div class="col-5 ps-0 mb-5">
      <div class="h5 mb-0">${$(".main_city_name").text()}</div>
      <p class="text-thin m-b-1 fs-xs text-white">${val.data.weather.desc}</p>
      </div>
      <div class="col-3 d-flex align-items-top justify-content-end pe-0">
      <h3 class="fw-bold temperature-degree">${val.data.weather.temp}</h3>
      </div>
      </div>` + `<div class="row weather-admin-forecast"> ${w_html_list} </div>
      <div class="row">
      <div class="d-flex justify-content-end">
      <p class="text-thin text-white fs-xs mt-3 mb-0 me-2">Quelle: <a href="http://www.wetter.net" target="_blank">http://www.wetter.net</a> | ${requested_at.format('ddd, HH:mm')} Uhr</p>
      </div>
      </div>`;
  $('#add-weather-info-admin').append(w_html_);

}
async function getGarbageDistrict() {
  const response = await fetch('/get_garbage_district', {
    method: 'get',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  }).then(res => res.clone().json())
  return response;
}

function addnextEvens() {
  let val = JSON.parse(window.localStorage.getItem('next_events'));
  let event_html_list = '';
  let time_now = new Date();
  val && val.data?.data.forEach(event => {
    let event_date = event["start_time"] ? moment(event["start_time"]).lang("de") : time_now;
    let event_date_finish = event["finish_time"] ? moment(event["finish_time"]).lang("de") : time_now;
    let is_single_day = event_date.format('MM.DD.YYYY') === event_date_finish.format('MM.DD.YYYY');
    let event_date_actual = is_single_day ? event_date : (event_date > time_now ? event_date : time_now);
    let event_display_date = is_single_day ? moment(event_date).format("dddd, DD.MM.YYYY") : moment(event_date).format("dddd, DD.MM.YYYY") + ' - ' + moment(event_date_finish).format("dddd, DD.MM.YYYY");
    let opening_hr = event["start_time_whole_day"] ? "Ganztägig" : (new Date(event["start_time"]).toLocaleTimeString([], {
      hourCycle: 'h23',
      hour: '2-digit',
      minute: '2-digit'
    }) + " - " + new Date(event["finish_time"]).toLocaleTimeString([], {
      hourCycle: 'h23',
      hour: '2-digit',
      minute: '2-digit'
    }) + " Uhr")
    let is_cancled = event["cancelled"] ? `<div class="row mb-2"><div class="col-12"><div class="badge bg-danger fw-bold">ABGESAGT</div></div></div>` : ``;
    let event_dates_ = (Date(event["start_time"]) !== Date(event["finish_time"])) ? (new Date(event["start_time"]).toLocaleDateString(["de-DE"], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }) + " - " + new Date(event["finish_time"]).toLocaleDateString(["de-DE"], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    })) : '';
    let image_html = event["images_data"] ? ` <div class="img-blur-wrap"><div class="img-blur-bg" style="height: 150px; background-image: url('${event["images_data"][0]["orig"]}');"></div><div class="img-blur" style="height: 150px; background-size: cover; background-image: url('${event["images_data"][0]["orig"]}');"></div></div>` : `<div class="img-blur-wrap"><div class="img-blur-bg event_no_img" style="height: 150px;"></div><div class="img-blur event_no_img" style=" height: 150px; background: #efefef;"></div></div>`;

                // avoid "Brey, Brey" for address
                let address = "";
                if (event["address"]["place"] != undefined) {
                    if (event["address"]["city"] == event["address"]["place"]) {
                        // console.log(`ist gleich: '${event["address"]["city"]}' == '${event["address"]["place"]}'`)
                        address = event["address"]["city"];
                    } else {
                        // console.log(`ist nicht gleich: '${event["address"]["city"]}' != '${event["address"]["place"]}'`)
                        address = `${event["address"]["city"]}, ${event["address"]["place"]}`;
                    }
                } else {
                    address = event["address"]["city"];
                }

                event_html_list += `<a class="link-dark text-decoration-none col-lg-4 pt-2 mb-3 pe-lg-0" href="/events/${event["lw_id"]}"><div class="event-module"><div class="thumbnail">
                <div class="date bg-primary"><div class="month">${new Date(event_date_actual).toLocaleDateString('de-DE', { month: 'short' })}</div>
                <div class="day common-text-black">${new Date(event_date_actual).toLocaleDateString('de-DE', { day: 'numeric' })}</div><div class="year">${new Date(event_date_actual).toLocaleDateString('de-DE', { year: 'numeric' })}</div>
                </div>${image_html}</div><div class="event-content pt-0">${is_cancled}<h6 class="mb-2 mt-2 pb-0 text-truncate text-truncate-3 title">${event["title"]}</h6><div class="row">
                <div class="col-12 event-place-text d-flex align-items-baseline"><div class="icon-event common-text-black"></div><span class="ps-2 common-text-black">${event_display_date}</span></div>
                <div class="col-12 event-place-text"><div class="icon-place common-text-black"></div><span class="ps-2 text-truncate common-text-black">${address}</span></div>
                <div class="col-12 event-place-text"><div class="icon-time-clock-circle common-text-black"></div>
                <span class="ps-2 common-text-black">${opening_hr}</span></div> ${event_dates_ !== "" ? `<div class="col-12 event-place-text"><div class="icon-event common-text-black"></div>
                <span class="ps-2 common-text-black">${event_dates_}</span></div>` : ""} </div><div class="row text-truncate mt-1"><div class="col-12 d-flex align-items-center">
                <span class="badge bg-common-black">~${(Math.round((event['distance'] + Number.EPSILON) * 10) / 10).toString().replace('.', ',')} km</span></div></div></div></div></a>`;
  });
  let html_to_events = `<div class="box-middle"><div class="row"><div class="col-12 mb-0 justify-content-between d-flex"><h2 class="fw-bold h4">Events <span class="d-lg-inline-block d-none">in und um Deinen Ort</span></h2><a href="/events" class="common-text-black">Alle Events</a></div><div class="col-12"><hr class="border-2 border-top border-gray mb-0 mt-0" /></div></div><ul class="row list-unstyled event-slick">${event_html_list}</ul></div>`;
  (val && val.data.data.length > 0) ? $('#next-event-container').append(html_to_events): $("#next-event-container").remove();
}
function initYoutubeJSLoad() {
  $('iframe[data-src*="youtube"]').each(function(index) {
    $(this).contents().find('body').css('font-family', 'sans-serif');
    $(this).contents().find('body').css({
      "background-color": "#f5f5f5",
      "text-align": "center",
      "background-image": "url(/icons/app_logo_watermark.svg)",
      "background-position": "center",
      "background-repeat": "no-repeat"
    });
    var sttr = ($(this).attr('data-src'));
    $(this).contents().find('body').html(`<p style='margin-top: 20%; line-height: 1.5;'>
    Dieser Inhalt wird von einer dritten Partei gehostet (<a href='https://www.youtube.com' target='_blank' style='color:#f7b624;'>YouTube</a>).
    Mit dem Anzeigen der externen Inhalte akzeptieren Sie die Bedingungen von <a href='https://www.youtube.com' style='color:#f7b624;' target='_blank'>YouTube</a></p>
    <a style='color: #ffffff;background: #f7b624 ;border: 1px solid #f7b624; padding: 8px; cursor: pointer; border-radius: 5px; text-align: center' id='load_youTube'>
    Externe Inhalte anzeigen</a>`);
    $(this).contents().find('#load_youTube').click(function() {
      load_conten(index);
    });
  });
}

function load_conten(index) {
  var i = index;
  var attr = $('iframe')[i].getAttribute('data-src');
  if (typeof attr !== typeof undefined && attr !== false) {
      $('iframe')[i].setAttribute("src", $('iframe')[i].getAttribute('data-src'));
  }
}

$(document).on('turbo:load', async function () {
  // To Load YouTube videos with consent
  // initYoutubeJSLoad();
  // if the user had entered the email for push-mail-subscribers and thus
  // there's a value for the key 'email-sub-token' in localStorage, do not show
  // the fields to enter email-address but instead show that he's been
  // already registered
  let token = window.localStorage.getItem('email-sub-token');
  if (token != undefined ) {
    $(".push-mail-subscriber-form").addClass('d-none'); // hide input-fields
    $("#push-mail-subscriber-form-not-neccessary").removeClass("d-none");
  }
  ($('#weather-info-to-view-box').length == 1) && ($("#weather-info-to-view-box").html().length < 3) && addWeatherToView();
  ($('#add-weather-info-admin').length == 1) && ($("#add-weather-info-admin").html().length < 3) && addWeather_info_admin();
  ($('#next-event-container').length == 1) && ($("#next-event-container").html().length < 3) && addnextEvens();
  if (document.location.href.includes('welcome')) {
    let garbage_districts_ = await getGarbageDistrict();
    window.localStorage.setItem('garbage_districts', JSON.stringify(garbage_districts_));
  }
  /** Sticky header dynamic height */
  if (document.location.href.includes('neues')) {
    if($("nav").height() > 150){
      $(".sticky-box-filter").css('top',`${$("nav").height() + 8}px`);
    }
  }

  if (location.pathname === "/" && redirectToWelcome()) { return; }

  /** Slide open */
  var slidebar_width = "350px";
  var slide_bar = $(".side-menu-wrapper");
  var slide_open_btn = $(".slide-menu-open");
  var slide_close_btn = $(".side-menu-overlay");
  var overlay = $(".side-menu-overlay");

  slide_open_btn.click(function (e) {
    e.preventDefault();
    slide_bar.css({
      "left": "0px"
    });
    overlay.css({
      "opacity": "1",
      "width": "100%"
    });
  });
  slide_close_btn.click(function (e) {
    e.preventDefault();
    slide_bar.css({
      "left": "-" + slidebar_width
    });
    overlay.css({
      "opacity": "0",
      "width": "0"
    });
  });
  $(document).on('click', '.left-close', function (e) {
    $(slide_close_btn).trigger('click');
  });
  /** Sliders */
  $('.slider-for.poi-images').not('.slick-initialized').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.slider-nav'
  });
  $('.slider-nav').not('.slick-initialized').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    centerMode: true,
    focusOnSelect: true,
    prevArrow: ".prev-button",
    nextArrow: ".next-button",
    responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
  $('.slider.poi-offer-cards').not('.slick-initialized').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: false,
    arrows: false,
    equalizeHeight: true,
    responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          equalizeHeight: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          equalizeHeight: true,
          dots: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          equalizeHeight: true,
          dots: true,
        }
      }
    ]

  });
  $('.slider.story-cards').not('.slick-initialized').slick({
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 7,
    dots: false,
    arrows: true,
    responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          dots: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          dots: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          dots: true,
        }
      }
    ]

  });
  $('.slider.poi-events-cards').not('.slick-initialized').slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    dots: true,
    arrows: false,
    responsive: [{
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]

  });
  $('.slider.greeting_cards_ads').not('.slick-initialized').slick({
    dots: false,
    infinite: false,
    centerMode: true,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: ".prev-button-ads",
    nextArrow: ".next-button-ads",
    centerMode: false,
  });
  $('.slider.latest_poi_cards').not('.slick-initialized').slick({
    dots: false,
    infinite: false,
    centerMode: true,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: ".prev-button-ads",
    nextArrow: ".next-button-ads",
    centerMode: false,
  });
  $('.slider.greeting_cards_family').not('.slick-initialized').slick({
    dots: false,
    infinite: true,
    slidesToShow: 2,
    centerMode: true,
    variableWidth: false,
    prevArrow: ".prev-button-family",
    nextArrow: ".next-button-family",
    infinite: false,
    centerMode: false,
  });
  $('.slider.greeting_cards_death').not('.slick-initialized').slick({
    dots: false,
    infinite: true,
    slidesToShow: 2,
    centerMode: true,
    variableWidth: false,
    prevArrow: ".prev-button-death",
    nextArrow: ".next-button-death",
    infinite: false,
    centerMode: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false
            }
        }
    ]
  });
  $('#reenter_email').bind("cut copy paste", function(e) {
      e.preventDefault();
  });
  $("#reenter_email, #email").each(function(){
  $(this).on("keyup", function () {
    if (
      $("#email").val() !== "" &&
      $("#reenter_email").val() !== "" &&
      $("#email").val() !== $("#reenter_email").val()
    ) {
      $("#reenter_email").addClass("is-invalid");
      $("#button_submit").addClass("disabled");
      $("form[name='push_email_sub']").removeClass("was-validated")
    }else {
      $("#reenter_email").removeClass("is-invalid");
      $("#button_submit").removeClass("disabled");
      $("form[name='push_email_sub']").addClass("was-validated")
    }
  });
  });
  // not avaiable in recent version, TODO SA, Responsive Slider for pois
  // $('.slider.poi_list_on_news').not('.slick-initialized').slick({
  //   dots: false,
  //   infinite: true,
  //   slidesToShow: 2,
  //   centerMode: true,
  //   variableWidth: false,
  //   prevArrow: ".prev-button-death",
  //   nextArrow: ".next-button-death",
  // });
  // Select the main city logo as favicon if present or take the favicon.ico.
  $(document).ready(function () {
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    let main_city_id = JSON.parse(window.localStorage.getItem("main_city"));
    if (main_city_id) {
      link.href = main_city_id["logo"];
    } else {
      link.href = '/favicon.ico';
    }
    $(".show_hidden_form").click(function() {
      $(".from_hidden").fadeIn(500, function() {
        $(this).css("display", "block");
      });
      $(this).css("display", "none");
    });
    // document.querySelector('#my-manifest-placeholder').setAttribute('href', '/manifest.json');
  });
  lightbox.init();
  lightbox.option({
                     'wrapAround': false,
                'positionFromTop': 150,
           'showImageNumberLabel': false,
    'alwaysShowNavOnTouchDevices': true,
               'disableScrolling': true,
                   'fadeDuration': 25,
              'imageFadeDuration': 25,
                 'resizeDuration': 25
  });
  if (document.location.href.includes('verwaltung')) {
    $( "img" ).each(function(index) {
      if(index > 1){
        if ($(this).hasClass("img-blur")){
          $(this).wrap(function(index) {
            return '<a href="'+$(this).attr('src')+'" data-lightbox="image-'+index+'"></a>';
          });
        }
      }
    });
  }

  $('.event-slick').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    dots: true,
    prevArrow: false,
    nextArrow: false,
    responsive: [{
      breakpoint: 768,
      settings: 'unslick'
    }]
  });

$('.root-image-slick').not('.slick-initialized').slick({
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: false,
});


  $('.slider.article-image-slider').not('.slick-initialized').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: false,
    nextArrow: false,
  });

  $('.slider.alphabet-slider').not('.slick-initialized').slick({
    infinite: false,
    speed: 300,
    adaptiveHeight: true,
    slidesToShow: 8,
    slidesToScroll:5,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          dots: false,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          dots: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
        }
      }
    ],
    prevArrow: false,
    nextArrow: false,
  });


  var swiper = new Swiper('.nav-swiper', {
    slidesPerView: 'auto',
    spaceBetween: 0,
    preventClicks: false,
    preventClicksPropagation: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    on: {
      sliderFirstMove: function () {
        $(".swiper-button-prev").removeClass('d-none')
      },
      reachEnd: function () {
        $(".swiper-button-next").addClass('d-none')
      },
      reachBeginning: function () {
        $(".swiper-button-prev").addClass('d-none')
        $(".swiper-button-next").removeClass('d-none')
      },
    },
  })
});

$(document).on('turbo:load', async function () {
  //scroll to appropriate anchor position
  $(".scroll").click(function(event) {
      event.preventDefault();
      var offset = $($(this).attr("href")).offset().top - 142;
      $('html,body').animate({
          scrollTop: offset
      });
  });
  $(".rotateCard").click(function()
   {
     rotateCard($(this));
   });
});

function rotateCard(btn){
  var $card = $(btn).closest('.card-container');
  if ($card.hasClass('hover')) {
    $card.removeClass('hover');
  } else {
    $card.addClass('hover');
  }
}

$(document).on('click', '.toggle_div', function (e) {
  toggle_div( $(e.currentTarget).data('target') );
});

$(document).on('click', 'a[data-clickable]', function (e) {
  let clazz = $(this).data('class');
  let id = $(this).data('id');
  let targetDivId = `${clazz}-${id}`;
  getAndUpdateEmployeeInfo(id, targetDivId);
  toggle_div(targetDivId);
});

function getAndUpdateEmployeeInfo(employee_id, targetDivId) {
  $.getJSON(`http://localhost:2999/lwconnectapi/v1/employees/${employee_id}.json`, function(json) {
    let contact_data = `<h3><b>${json.fullname}</b></h3>` +
                       `<p>`+
                       `Telefon: ${json.phone}<br>` +
                       `Fax: ${json.fax}<br><br>` +
                       `${json.email}<br><br>` +
                       `${json.address}` +
                       `</p>`;
    let abteilungen = `<div class=\"abteilungen\"><b>Abteilungen</b><br>${json.departments.map((j) => `<a href='/lwconnectapi/v1/entries/#{params[:id]}.json'>${j['name']}</a>`).join('<br>')}<div>`;
    let dienste =     `<div class=\"dienste\"><b>Dienste</b><br>${json.services.map((j) => `${j['name']}`).join('<br>')}<div>`;

    let html = `${contact_data}${abteilungen}${dienste}`;
    $(`#${targetDivId}`).html(html);
  });
}

function toggle_div(id) {
  console.log(`$("#${id}").toggle()`);
  let isHidden = $(`#${id}`).hasClass('d-none');
  if (isHidden) {
    $(`#${id}`).removeClass('d-none');
  } else {
    $(`#${id}`).addClass('d-none');
  }
}
