import map_icon from './../images/location-mark-orange';
import * as L from 'leaflet';
import * as L1 from 'leaflet.markercluster'

$(document).on('turbo:load', async function () {
  let root_element_ = $("#root-map-search");

  // continue if map is available on page
  if ($("#root-map-search").length == 0) { return }

  let orte_data = await getAllOrte();
  var mymap = L.map('root-map-search').setView([51.1657, 10.4515], 6);
  var mo_icon = L.icon({
    iconUrl: map_icon,
    iconSize: [35, 35],
  });
  L.tileLayer('https://osm2.wittich.de/tiles/osm/{z}/{x}/{y}.png?access_token={accessToken}', {
    attribution: ' Wittich Medien KG',
    maxZoom: 20,
    id: 'mapbox.streets',
    accessToken: 'lw-2108',
    "subdomains": 1234,
    "height": 100,
    "tileSize": 256,
    "maxHeight": 100
  }).addTo(mymap);
  var markersCluster = new L1.MarkerClusterGroup({ showCoverageOnHover: false });
  $.each(orte_data['data'], function (index, value) {
    var my_marker = L.marker(
      [parseFloat(value["lat"]), parseFloat(value["lon"])],
      { icon: mo_icon }
    // TODO: add Logo: <img src='${value["logo"]} style='width: 50px;'><a href='/welcome....
    ).bindPopup(`<a href='/welcome?ort=${value["id"]}'>${value["name"]}<br> ${value["zip_code"]}</a>`);
    markersCluster.addLayer(my_marker);
  });
  mymap.addLayer(markersCluster);
});

async function getAllOrte() {
  const response = await fetch('/all_orte', {
    method: 'get',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  }).then(res => res.clone().json())
  return response;
}
